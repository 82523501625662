import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "list-group-item h5" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 1,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Div = _resolveComponent("Div")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.checkpw)
      ? (_openBlock(), _createBlock(_component_Div, { key: 0 }, {
          default: _withCtx(() => [
            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "bi bi-search text-primary h3 mx-4 mt-4 position-absolute mt-1" }, null, -1 /* HOISTED */)),
            _withDirectives(_createElementVNode("input", {
              class: "form-control form-control-solid w-250px ps-14 border border-primary mb-4",
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchquery) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => ($setup.filtereddata())),
              placeholder: "Search title.."
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, $setup.searchquery]
            ]),
            _createElementVNode("div", _hoisted_1, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("span", { class: "h3 text-primary mt-4" }, "Production Files")
              ], -1 /* HOISTED */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.urls, (value, index) => {
                return (_openBlock(), _createElementBlock("ul", {
                  class: "list-group list-group-flush",
                  key: index
                }, [
                  _createElementVNode("li", _hoisted_2, [
                    _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-file-earmark-zip-fill text-primary h3 mx-2" }, null, -1 /* HOISTED */)),
                    _createElementVNode("span", null, _toDisplayString(value), 1 /* TEXT */),
                    _createElementVNode("a", {
                      href: `https://production.brighterbins.com/production/download/${value}`,
                      class: "btn btn-primary px-3 py-2",
                      download: ""
                    }, "Download ", 8 /* PROPS */, _hoisted_3)
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.errormsg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[5] || (_cache[5] = [
          _createElementVNode("h5", { class: "pt-5 pt-5 text-danger" }, " You are not authorized to access this data! ", -1 /* HOISTED */)
        ])))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}